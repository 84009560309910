.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 70px;
  line-height: 70px;
  background-color: #001529;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header .logo {
  display: flex;
  align-items: center;
}

.header .logo img {
  height: 40px;
  margin-right: 10px;
}

.header .title {
  color: #ffffff;
  font-size: 1.5em;
  margin: 0;
}

.status-indicator {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: white;
}

.select-language {
  display: flex;
  margin-left: 5px;
  background-color: #001529 !important;
  border: none;
}