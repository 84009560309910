.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transition: all 0.3s;
  z-index: 1;
  height: auto;
  margin-top: 70px;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    margin: 16px;
  }
}

.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: #000 !important;
  border-right: 1px solid #e8e8e8 !important;
  border-top: 1px solid #e8e8e8;
}

.ant-layout-sider-trigger:hover {
  background-color: #e6f4ff !important;
  color: #1677ff !important;
}

.ant-layout-sider-trigger:active {
  background-color: #e6f4ff !important;
  color: #1677ff !important;
}
