/* Reset box model and margin/padding */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Basic styles for body */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Ensure the layout fills the viewport height and has proper spacing */
html,
body,
#root {
  height: 100%;
}

/* Styles for the header */
.header {
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
}

/* Styles for the logo and toggle button in the header */
.logo {
  display: flex;
  align-items: center;
  padding: 0 16px;
}

/* Sidebar styles */
.sidebar-container {
  height: 100vh;
  overflow: auto;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transition: all 0.3s;
}

.menu-toggle {
  display: none; /* Hide the menu toggle button by default */
}

/* Show the menu toggle button on small screens */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    margin: 16px;
  }

  .sidebar {
    width: 80px; /* Collapsed width */
  }
}
